import CookieConsent from 'react-cookie-consent'
import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

class CookieBar extends React.Component {
    render() {
        const { intl } = this.props
        return (
            <CookieConsent
                disableButtonStyles
                disableStyles
                location="bottom"
                buttonText={intl.formatMessage({
                    id: 'cookies.accept',
                })}
                acceptOnScroll={true}
                containerClasses="is-cookie-bar is-cookie-bar-fixed py-4 px-4 has-text-centered"
                buttonClasses="button is-primary mt-4 is-size-6"
                cookieName="gatsby-gdpr-google-analytics"
            >
                <h2 className="is-title-color is-size-5">
                    <FormattedMessage id="cookies.policy" />
                </h2>
                <p className="mt-4 is-font-secondary">
                    <FormattedMessage id="cookies" />
                </p>
            </CookieConsent>
        )
    }
}

export default injectIntl(CookieBar)
